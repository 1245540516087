<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card pb-1">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div v-if="invoiceData.branch">
                <div class="logo-wrapper m-0">
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo m-0">
                    {{ invoiceData.branch.company.name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{ invoiceData.branch.name }}
                </p>

                <p class="card-text mb-25">
                  {{ invoiceData.branch.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ $t("Tax Number") }} :
                  {{ invoiceData.branch.company.tax_number }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4>Tax Invoice - فاتوره ضريبية</h4>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{ $t("Invoice Number") }}</p>
                  <p class="invoice-date">#{{ invoiceData.serial }}</p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{ $t("Date Issued") }}</p>
                  <p class="invoice-date">
                    {{ invoiceData.invoice_date | formatDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr style="width: 100%; height: 2px; background: #7367f0" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="invoiceData.client" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="4" class="p-0">
                <h6 class="mb-1">{{ $t("Invoice To") }}</h6>
                <h6 class="mb-0">
                  {{ invoiceData.client.name }}
                </h6>

                <p class="card-text mb-0">
                  {{ invoiceData.client.address }}
                </p>

                <p class="card-text mb-0">
                  {{ invoiceData.client.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ $t("Tax Number") }} : {{ invoiceData.client.tax_number }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="8" cols="12" class="p-0">
                <div
                  v-if="
                    invoiceData.transaction && invoiceData.transaction != null
                  "
                >
                  <h6 class="mb-1">{{ $t("Transaction") }}</h6>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>{{ $t("Serial") }}</td>
                        <td>
                          <span class="font-weight-bold">{{
                            invoiceData.transaction.serial
                          }}</span>
                        </td>
                        <td>{{ $t("Date") }}</td>
                        <td>
                          {{ invoiceData.transaction.trans_date | formatDate }}
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("Policy Number") }}</td>
                        <td>
                          {{ invoiceData.transaction.policy_number }}
                        </td>
                        <td>{{ $t("Number of Containers") }}</td>
                        <td>
                          <span class="font-weight-bold">{{
                            invoiceData.transaction.number_of_containers
                          }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t("Delivary Permission") }}</td>
                        <td>
                          {{ invoiceData.transaction.delivary_permission }}
                        </td>
                        <td>{{ $t("Import Declaration") }}</td>
                        <td>
                          {{ invoiceData.transaction.import_declaration }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="
                invoiceData.transaction &&
                invoiceData.transaction != null &&
                settings['general_settings'][
                  'display_containers_numbers_inside_clearance_invoice_report'
                ] == '1'
              "
              class="invoice-spacing"
            >
              <b-col cols="12" class="p-0">
                <div>
                  <h3></h3>
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <tr>
                        <td>{{ $t("Containers Numbers") }}</td>
                        <td>
                          <span
                            v-for="(con, ind) in invoiceData.transaction
                              .containers"
                            :key="ind"
                            class="m-1 text-success"
                          >
                            {{ con.container }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <hr style="width: 100%; height: 2px; background: #7367f0" />
          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            striped
            :items="invoiceData.clerance_invoice_items"
            :fields="invoiceItemsColumns"
          >
          </b-table-lite>
          <hr style="width: 100%; height: 2px; background: #7367f0" />
          <b-card-body class="invoice-padding pb-1">
            <b-row>
              <b-col cols="12" md="5">
                <table class="table table-bordered">
                  <tr>
                    <td>
                      {{ $t("Advanced Payments") }}
                    </td>
                    <td>
                      {{ invoiceData.advanced_payment }}
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("Cash Payments") }}</td>
                    <td>
                      {{ invoiceData.cash_payment }}
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("Later Payments") }}</td>
                    <td>
                      <b
                        >{{
                          invoiceData.later_payment | globalNumberDisplay
                        }} </b
                      ><span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                </table>
              </b-col>

              <b-col cols="12" md="7">
                <table class="table table-bordered">
                  <tr>
                    <td>{{ $t("Total Without Vat") }}</td>
                    <td>
                      <b>{{
                        invoiceData.total_without_vat | globalNumberDisplay
                      }}</b>
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("Vat") }}</td>
                    <td>
                      <b>{{ invoiceData.total_vat | globalNumberDisplay }}</b>
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("Discount") }}
                    </td>
                    <td>
                      <b>{{ invoiceData.discount | globalNumberDisplay }}</b>
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("Total With Vat") }}</td>
                    <td>
                      <b>{{
                        invoiceData.total_with_vat | globalNumberDisplay
                      }}</b>
                      <span class="float-right">{{
                        invoiceData.currancy.symbol
                      }}</span>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card-body>
          <hr style="width: 100%; height: 2px; background: #7367f0" />
          <span class="d-block text-center text-danger">
            ## {{ tafqit }} ##
          </span>
          <hr style="width: 100%; height: 2px; background: #7367f0" />
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click.prevent="downloadFile"
          >
            <feather-icon icon="DownloadIcon" class="mr-25" />
            <span>{{ $t("Download") }}</span>
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click.prevent="printInvoice"
          >
            <feather-icon icon="PrinterIcon" class="mr-25" />
            <span>{{ $t("Print") }}</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <iframe id="pdf-frame"></iframe>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import fileDownload from "js-file-download";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
  },
  data() {
    return {
      invoiceData: {
        currancy: {},
      },
      settings: [],
      tafqit: 0,
      Loading: false,
      invoiceItemsColumns: [],
    };
  },
  created() {
    this.$store.commit("START_LOADING");
    this.$http
      .get(`crm/client/invoices/${this.$route.params.id}/details`)
      .then((response) => {
        this.invoiceData = response.data.clearanceInvoice;
        this.tafqit = response.data.tafqit;
        this.settings = response.data.settings;
        this.setInvoiceItemsColumns();
        this.$store.commit("STOP_LOADING");
      });
  },
  methods: {
    printInvoice() {
      this.Loading = true;
      this.$http
        .get(`crm/client/invoices/${this.invoiceData.id}/print`, {
          responseType: "blob",
          params: this.serverParams,
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          var objectURL = URL.createObjectURL(file);
          // window.open(objectURL);

          document.querySelector("#pdf-frame").src = "";
          document.querySelector("#pdf-frame").src = objectURL;
          // objectURL = URL.revokeObjectURL();
          setTimeout(function () {
            URL.revokeObjectURL(file);
          }, 3000);
        })
        .then(function () {
          window.setTimeout(function () {
            document.querySelector("#pdf-frame").contentWindow.print();
          }, 1000);
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    downloadFile() {
      this.$http
        .get(`crm/client/invoices/${this.invoiceData.id}/download`, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, `transaction-${this.invoiceData.id}.pdf`);
        });
    },
    setInvoiceItemsColumns() {
      var list = [
        { key: "invoice_item.name", label: this.$t("Item") },
        { key: "value", label: this.$t("Value") },
      ];

      if (
        this.settings["general_settings"] &&
        this.settings["general_settings"][
          "display_currancy_and_currancy_price"
        ] == "1"
      ) {
        list.push(
          { key: "currancy.symbol", label: this.$t("Currancy") },
          { key: "currancy_price", label: this.$t("Currancy Price") },
          { key: "value_in_local", label: this.$t("Value In Local") }
        );
      }
      list.push(
        { key: "tax_type.name", label: this.$t("Tax Type") },
        { key: "vat", label: this.$t("Vat") }
      );

      if (
        this.settings["general_settings"] &&
        this.settings["general_settings"]["display_amount_and_unit_price"] ==
          "1"
      ) {
        list.push(
          { key: "units", label: this.$t("Units") },
          { key: "unit_price", label: this.$t("Unit Price") }
        );
      }

      list.push({ key: "total_with_vat", label: this.$t("Total") });

      this.invoiceItemsColumns = list;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style scoped>
::v-deep #pdf-frame {
  visibility: hidden;
  max-width: 20px;
}
</style>
